import React from "react";
import ReactDOM from "react-dom";
import "assets/css/App.css";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import AdminLayout from "layouts";
// Chakra imports
import { ChakraProvider } from "@chakra-ui/react";
import Success from "layouts/success";
import theme from "theme/theme";

ReactDOM.render(
  <ChakraProvider theme={theme}>
    <React.StrictMode>
      <BrowserRouter>
        <Routes>
          <Route path={`/success-message`} element={<Success />} />
          <Route path={`/success-message-ai`} element={<Success />} />
          <Route path={`/`} element={<AdminLayout />} />
        </Routes>
      </BrowserRouter>
    </React.StrictMode>
  </ChakraProvider>,
  document.getElementById("root")
);
